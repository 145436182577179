.user-groups {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  min-width: 0;

  .table-container {
    width: 100%;
    overflow: scroll;
  }
}

.modal-text {
  margin-bottom: 24px;
}