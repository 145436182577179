.ota {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.ota > div:not(:last-child) {
  padding-bottom: 20px;
}

.ota-deploy-area {
  padding: 20px;
  flex:1;
  box-shadow: none !important;
}

.ota-deploy-area > .title {
  margin: 0;
  margin-bottom: 20px;
}

.ota-deploy-area > div:not(:last-child) {
  padding-bottom: 20px;
}

.ota-upload {
  width: 100%;
  box-sizing: border-box;
}

.ota-upload-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
}

.ota-fleet-status-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.ota-fleet-status-row > :not(:last-child) {
  margin-right: 20px;
}

.ota-binary-cfg-errors {

}

.ota-upload-btn {
}


.ota-bottom-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.ota-job-area {
  flex:1;
  box-shadow: none !important;
}

.ota-job-area > .title {
  margin: 0;
  margin-bottom: 20px;
}