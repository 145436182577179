.coredumps-overview {
  flex: 1;

  & > .query-row {
    padding: 20px;

    & > .inner {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;

      & .query-input {
        flex: 1;
      }
    }
  }

  & .pagination {
    width: 100%;
  }

  & > .coredumps-loading {
    width: 100%;
  }

  & .device-row {
    cursor: pointer;
  }
}




