.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;

  & .navbar {
    padding: 20px 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    & > .nav-links {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      & .nav-link {
        margin: 0 20px;
        text-decoration: none;
        font-size: 18px;
        color: green;

        &.active {
          color: blue;
        }
      }
    }

    & > .title {
      margin: 0;
    }
  }
}
