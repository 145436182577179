.tag-search-modal-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #FFFFFF66;
  }

  & .content {
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 70vh;
    padding: 20px;
    z-index: 999;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    & .header {
      padding: 0;
      padding-bottom: 10px;
      margin: 0;
    }

    & .tag-table-container {
      flex: 1;
      max-height: 100%;
    }
  }

  & .action-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;

    & > button {
      margin-left: 20px;
    }
  }
}
