.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.7);
}

.confirmation-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  z-index: 10;
  position: absolute;
  top: 35%;
  left: 35%;
  background: #ffffff;
  border: 2.43756px solid #ececec;
  box-sizing: border-box;
  box-shadow: -7.31267px 23.5631px 32.5008px rgba(55, 41, 105, 0.14);
  border-radius: 8.12519px;
}

.confirmation-modal-titlespace {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 95%;
  justify-content: space-between;
  border-bottom: 2.43756px solid #f3f3f3;
  margin-bottom: 4%;
}

.confirmation-modal-titlespace-span-container {
  display: flex;
  flex-direction: row;
  flex-wrap: none;
  width: 75%;
  padding: 3%;
  align-items: center;
}

.confirmation-modal-titlespace-span {
  font-style: normal;
  font-weight: bold;
  font-size: 150%;
  line-height: 100%;
  letter-spacing: 0.1em;
}

.confirmation-modal-close {
  background: none;
  border-radius: 10px;
  width: 5%;
  height: 5%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 90%;
  border: none;
  margin: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.confirmation-modal-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 100%;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 20px;
}

.confirmation-modal-action {
  width: 100%;
  display: flex;
  padding: 16px 0;
  justify-content: center;
}

.confirmation-modal-button {
  background: white;
  min-height: 40px;
  font-weight: bold;
  padding: 0 10px;
  margin: 0 0 10px 0;
  font-size: 16px;
  letter-spacing: 0.1em;
  cursor: pointer;
}

.confirmation-modal-button:hover {
  background: #d7c6ff;
  border: none;
}

.confirmation-modal-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 10%;
  margin-bottom: 5%;
  justify-content: space-between;
}
.confirmation-modal-input-field {
  border: 1px solid rgba(55, 41, 105, 0.14);
  padding-left: 3%;
  padding-bottom: 1%;
  padding-top: 1%;
  width: 50%;
  outline-color: rgba(55, 41, 105, 0.8);
}
