.fleet-overview-toolbar {
  width: 100%;
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-content: center;
  padding: 10px;

  & > * {
    margin-left: 10px !important;
  }
}
