.simulations-table-row {
  background-color: #f9f9f9;
  transition: background-color 0.2s ease-in-out;
}

.simulations-table-row:hover {
  background-color: #f1f1f1;
}

.simulations-table-cell {
  padding: 8px !important;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.simulations-table-cell-actions {
  text-align: center;
}


.simulations-zener-position {
  display: flex;
  flex-wrap: wrap;
  gap: 12px !important;
  align-items: center;
}
