.select-input fieldset {
  border: transparent !important;
  border: none;
  text-align: center;
}

.label-text {
  display: inline-block;
  max-width: 130px;
  min-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-input .MuiOutlinedInput-notchedOutline {
  border : 0;
}


.MuiInputBase-adornedEnd {
  padding-right: 0 !important;
}

.input-box {
  display: flex;
  align-items: center;
  gap: 1;
}