.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.simulate-details-container {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  box-sizing: border-box;
  height: calc(100vh);

  .heatmap-canvas {
    width: 100%;
    height: calc(100vh - 250px);
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  .MuiGrid-root {
    box-sizing: border-box;
  }

  .side-panel {
    padding: 16px;
    box-sizing: border-box;

    @media (min-width: 1024px) {
      max-width: 400px;
      flex: 0 0 25%;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .icon {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.8);

  }
  .icon:hover {
    background-color: rgba(255, 255, 255, 1) !important;
  }
}

