.ota {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.ota-fleet-status-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
