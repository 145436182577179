.status-graph {
  width: 100%;

  & .details-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    & .last-updated-field {
      display: block;
    }
  }

  & .chart-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      padding-right: 10px;

    & .trace-labels-container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      align-items: flex-end;
      height: 150px;
      width: 14%;

      & > * {
        display: block;
      }
    }
  }

  & .tooltip {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #AAA;
    padding: 10px;
    font-size: 14px;

    & .tooltip-row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  & .state-legend {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 10px;

    & > * {
      margin: 0 10px;
    }
  }
}
