.device-attribute-editor {
  padding: 20px;

  & > .ceil-height-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    & > .ceil-height-input {
      margin-right: 20px;
    }
  }
}
