.job-id {
    cursor:pointer;
}

.jobId:hover {
    text-decoration: underline;
}

.table-row {
    height: 56px;
  }

.menu-col {
    padding: 0 !important;
  }

.cancel {
  color: #a6a6a6 !important;
}

.complete {
  color: #3182c3 !important;
}


.selected-row {
  background-color: #f0f8ff; 
  transition: background-color 0.3s ease;
}