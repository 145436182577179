.device-details {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.device-details > * {
  margin-bottom: 20px;
}

.device-details-header-container {
  padding: 20px;
}

.device-details-header-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.device-details-edit-button {
  margin-bottom: 10px;
}

.device-details-device-id {
  font-size: 40px;
  margin: 0;
}

.device-details-device-version {
  margin: 0;
  opacity: 0.8;
}

.device-details-device-name {
  margin: 0;
  margin-bottom: 20px;
  opacity: 0.6;
}

.device-details-device-owner-id {
  display: block;
  margin: 0;
  opacity: 0.8;
}

.device-details-device-description {
  display: block;
  margin-top: 10px;
  opacity: 0.8;
}

.device-details-device-description-undefined {
  opacity: 0.6;
}

.device-status-container {
  padding: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.device-details-status-item {
  display: flex;
  flex-flow: column nowrap;
}

.device-details-status-item-name {
  font-weight: 600;
}

.device-details-status-item-value {
  font-size: 40px;
}

.device-details-control-item {
  display: flex;
  flex-flow: column nowrap;
}

.device-details-control-item-name {
  font-weight: 600;
}

.device-details-control-item-value {
  font-size: 40px;
}

.edit-details-modal-input-container {
  margin-top: 15px;
}

.edit-details-modal-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-details-modal-close {
  background: none;
  border-radius: 10px;
  width: 5%;
  height: 5%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 90%;
  border: none;
  margin: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete-device {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-buttons {
  margin: 10px 30px !important;
}

.confirm-delete-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
